import { Component } from '@angular/core';
import { BaseList } from '@app/admin/base/list';
import { Const } from '@const/Const';
import { InputHelper } from '@services/input-helper';
import { ResponseAdminFinBatchListItem } from '@wearewarp/types/rest-api/admin/fin';
import { FinApCountTab, FinApTabFilter } from '../interface';
import { Utils } from '@services/utils';

@Component({
  selector: 'fin-payable-batch-list',
  templateUrl: './index.html',
  styleUrls: ['../../../list.scss', './index.scss']
})
export class FinPayableBatchList extends BaseList<ResponseAdminFinBatchListItem> {
  private _selectedTabIndex = 0;
  public countSum: FinApCountTab = {}

  constructor() {
    super();
  }

  get currentTabFilter(): FinApTabFilter {
    switch (this._selectedTabIndex) {
      case 0: return FinApTabFilter.needPodUpload;
      case 1: return FinApTabFilter.needPodConfirm;
      case 2: return FinApTabFilter.readyToProcess;
      case 3: return FinApTabFilter.alreadyProcessed;
      case 4: return FinApTabFilter.notFinished;
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any): void {
    super.handleNavigationEnd(url, prevQueryParam);
    this.countAll();
  }

  showBatchCode(item) {
    return `AP-${item.code}`;
  }

  getRouterLinkDetail(item) {
    return `${Const.routeAdminFinAP}/${item.id}`;
  }

  public getAmountValue(item) {
    return '$' + InputHelper._formatMoney(item.amt, 0);
  }

  onFilterTabChanged(value) {
    this._selectedTabIndex = value;
  }

  protected getApiUrl(): string {
    return this.getApiByTab(this.currentTabFilter);
  }

  private updateCountSum(tab: FinApTabFilter, value: number) {
    this.countSum = {...this.countSum, ...{[tab]: value}};
  }

  private countAll() {
    [
      FinApTabFilter.notFinished,
      FinApTabFilter.needPodUpload,
      FinApTabFilter.needPodConfirm,
      FinApTabFilter.readyToProcess,
      FinApTabFilter.alreadyProcessed,
    ].map(it => this.countForTab(it));
  }

  private countForTab(tab: FinApTabFilter) {
    const params = this.prepareParamGetList();
    const url = Utils.appendQueryStringIntoUrl(`${this.getApiByTab(tab)}?countOnly=true`, params);
    this.api.GET(url).subscribe(
      resp => {
        if (resp?.data?.total != null) {
          this.updateCountSum(tab, resp.data.total);
        }
      }, err => {
        this.showErr(err);
      }
    );
  }

  private getApiByTab(tab: FinApTabFilter) {
    switch (tab) {
      case FinApTabFilter.notFinished: return Const.APIURI_FINANCES_BATCH('list_not_finished');
      case FinApTabFilter.needPodUpload: return Const.APIURI_FINANCES_BATCH('list_need_pod_upload');
      case FinApTabFilter.needPodConfirm: return Const.APIURI_FINANCES_BATCH('list_need_pod_confirm');
      case FinApTabFilter.readyToProcess: return Const.APIURI_FINANCES_BATCH('list_ready_to_process');
      case FinApTabFilter.alreadyProcessed: return Const.APIURI_FINANCES_BATCH('list_already_processed');
    }
  }

  batchStatus(item: ResponseAdminFinBatchListItem) {
    return item.finJobs[0].intermediateStatus;
  }
}