<div *ngIf="!shouldShowBatchHyperLink" class="text-batch-code bottom15">
  Batch {{showBatchCode()}}
</div>
<div *ngIf="shouldShowBatchHyperLink" class="text-batch-code bottom15">
  <a [routerLink]="getRouterLink()" target="_blank" class="link">Batch {{showBatchCode()}}</a>
</div>


<ng-container *ngIf="isAR">
  <div class="biz-entity">
    <div class="label">Order</div>
    <a target="_blank" [routerLink]="routerLinkBizEntity()">{{data.relatedBiz.code}}</a>
  </div>
  <div class="account top10">
    <div class="label">Customer</div>
    <a target="_blank" class="link" [routerLink]="routerLinkAccount()">{{data.account.name}}</a>
  </div>
</ng-container>

<ng-container *ngIf="isAP">
  <div class="biz-entity">
    <div class="label">Route</div>
    <a target="_blank" [routerLink]="routerLinkBizEntity()">{{data.relatedBiz.code}}</a>
  </div>
  <div class="account top10">
    <div class="label">Carrier</div>
    <a target="_blank" class="link" [routerLink]="routerLinkAccount()">{{data.account.name}}</a>
  </div>
</ng-container>

<div *ngFor="let item of listFinJobs" class="top15">
  <div batch-fin-job [finJob]="item" [finType]="data.finType" [parentFinJob]="item.parent"></div>
</div>
