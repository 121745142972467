import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common';
import { FinBatch } from './comp';
import { ModuleBatchFinJob } from '../batch-fin-job/module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ModuleBatchFinJob,
  ],
  declarations: [FinBatch],
  exports: [FinBatch]
})
export class ModuleFinBatch {
}