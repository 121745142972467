import { Component } from '@angular/core';
import { BaseList } from '@app/admin/base/list';
import { Const } from '@const/Const';
import { InputHelper } from '@services/input-helper';
import { Utils } from '@services/utils';
import { ResponseAdminFinBatchListItem } from '@wearewarp/types/rest-api/admin/fin';
import { FinArTabFilter, FinArCountTab } from '../interface';

@Component({
  selector: 'fin-receivale-batch-list',
  templateUrl: './index.html',
  styleUrls: ['../../../list.scss', './index.scss']
})
export class FinReceivableBatchList extends BaseList<ResponseAdminFinBatchListItem> {
  private _selectedTabIndex = 0;
  public countSum: FinArCountTab = {}

  constructor() {
    super();
  }

  get currentTabFilter(): FinArTabFilter {
    switch (this._selectedTabIndex) {
      case 0: return FinArTabFilter.needPodUpload;
      case 1: return FinArTabFilter.needPodConfirm;
      case 2: return FinArTabFilter.readyToProcess;
      case 3: return FinArTabFilter.alreadyProcessed;
      case 4: return FinArTabFilter.notFinished;
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any): void {
    super.handleNavigationEnd(url, prevQueryParam);
    this.countAll();
  }

  protected onGetDataSucceeded(resp: any): void {
    if (resp?.data?.total) {
      this.updateCountSum(this.currentTabFilter, resp.data.total);
    }
  }

  showBatchCodeAR(item) {
    return `AR-${item.code}`;
  }

  getRouterLinkDetail(item) {
    return `${Const.routeAdminFinAR}/${item.id}`;
  }

  public getAmountValue(item) {
    return '$' + InputHelper._formatMoney(item.amt, 0);
  }

  onFilterTabChanged(value) {
    this._selectedTabIndex = value;
  }

  protected getApiUrl(): string {
    return this.getApiByTab(this.currentTabFilter);
  }

  private updateCountSum(tab: FinArTabFilter, value: number) {
    this.countSum = {...this.countSum, ...{[tab]: value}};
  }

  private countAll() {
    [
      FinArTabFilter.notFinished,
      FinArTabFilter.needPodUpload,
      FinArTabFilter.needPodConfirm,
      FinArTabFilter.readyToProcess,
      FinArTabFilter.alreadyProcessed,
    ].map(it => this.countForTab(it));
  }

  private countForTab(tab: FinArTabFilter) {
    const params = this.prepareParamGetList();
    const url = Utils.appendQueryStringIntoUrl(`${this.getApiByTab(tab)}?countOnly=true`, params);
    this.api.GET(url).subscribe(
      resp => {
        if (resp?.data?.total != null) {
          this.updateCountSum(tab, resp.data.total);
        }
      }, err => {
        this.showErr(err);
      }
    );
  }

  private getApiByTab(tab: FinArTabFilter) {
    switch (tab) {
      case FinArTabFilter.notFinished: return Const.APIURI_FINANCES_BATCH('list_not_finished');
      case FinArTabFilter.needPodUpload: return Const.APIURI_FINANCES_BATCH('list_need_pod_upload');
      case FinArTabFilter.needPodConfirm: return Const.APIURI_FINANCES_BATCH('list_need_pod_confirm');
      case FinArTabFilter.readyToProcess: return Const.APIURI_FINANCES_BATCH('list_ready_to_process');
      case FinArTabFilter.alreadyProcessed: return Const.APIURI_FINANCES_BATCH('list_already_processed');
    }
  }

  batchStatus(item: ResponseAdminFinBatchListItem) {
    return item.finJobs[0].intermediateStatus;
  }
}