<div style="display: flex; justify-content: space-between;">
  <div class="text-label1" style="display: flex;">
    {{showFinJobCode(finJob)}}
    <div class="text-fin-job-status" [ngClass]="{'text-fin-job-status-done': isFinJobDone(finJob)}">{{showFinJobStatus(finJob)}}</div>
  </div>
  <div class="text-fin-job-amt">
    <div class="text-fin-job-amt-desc right20">{{getAmtDesc(finJob)}}</div>
    {{showFinJobAmt(finJob)}}
  </div>
</div>
<div style="display: flex; justify-content: space-between;">
  <div class="top10">
    <div *ngIf="parentFinJob" class="biz-entity">
      <div class="label">{{showRelatedBizType()}}</div>
      <a target="_blank" [routerLink]="routerLinkRelatedBiz()">{{showRelatedBizCode()}}</a>
    </div>
    <div *ngIf="isAR" class="top10">Invoice: N/A</div>
  </div>
  <div class="text-breakdown clickable top10" (click)="toggleFinJobBreakdown()">View breakdown <i nz-icon nzType="down" nzTheme="outline" [nzRotate]="isExpand ? 0 : -90"></i></div>
</div>
<nz-collapse nzGhost [nzExpandIconPosition]="'right'">
  <nz-collapse-panel class="finjob-breakdown" [nzHeader]="" [(nzActive)]="isExpand" [nzShowArrow]="false">
    <div class="hline"></div>
    <div fin-job-breakdown [finType]="finType" [finJobId]="finJob.id" [parentFinJob]="parentFinJob"></div>
  </nz-collapse-panel>
</nz-collapse>