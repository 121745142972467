<div *ngIf="onProgress" class="nodata"><i nz-icon nzTheme="outline" nzType="loading"></i></div>

<div *ngIf="!onProgress" class="dashboard-child-container no-padding">
  <div class="box-line bottom15" style="width: 100%; padding: 20px">
    <div style="width: 100%" class="flex flex-space-between">
      <div class="flex flex-wrap">
        <div>
          <a [routerLink]="[routeAdminFinAR]" class="text-secondary">Accounts Payable Finance</a>
          <ng-container *ngIf="model"> / <span>{{showBatchCode(model)}}</span></ng-container>
        </div>
      </div>
    </div>
  </div>

  <div style="width: 100%; background: white; padding: 20px" class="bottom15">
    <div fin-batch [data]="model"></div>
  </div>

  <nz-tabset>
    <nz-tab nzTitle="Related receivable batches" class="related-batched">
      <div *ngIf="getRelatedBatchesLoading" class="nodata"><i nz-icon nzTheme="outline" nzType="loading"></i></div>
      <div *ngIf="getRelatedBatchesError" class="nodata">{{getRelatedBatchesError}}</div>
      <div *ngIf="!getRelatedBatchesLoading && !getRelatedBatchesError && relatedBatches.length == 0" class="nodata">Found no related batch</div>
      <ng-container *ngIf="relatedBatches.length > 0">
        <div *ngFor="let item of relatedBatches" style="width: 100%; background: white; padding: 20px" class="bottom15">
          <div fin-batch [data]="item"></div>
        </div>
      </ng-container>
    </nz-tab>
    <nz-tab nzTitle="Financial issues" class="financial-issues">
      <div style="width: 100%;background: white;padding: 20px">
        <div carrier-cost-issue [jobId]="routeId" [saleRepEmails]="saleRepEmails"></div>
      </div>
    </nz-tab>
  </nz-tabset>

</div>