import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { FinReceivableBatchList } from './batch-list';
import { ModuleFinReceivableBatchListFilter } from './batch-list/filter/module';
import { FinReceivableBatchDetail } from './batch-detail';
import { ModuleFinBatch } from '../components/batch/module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NzTableModule,
    NzTabsModule,
    NzIconModule,
    ModuleFinReceivableBatchListFilter,
    ModuleFinBatch,
  ],
  declarations: [FinReceivableBatchList, FinReceivableBatchDetail],
  exports: [FinReceivableBatchList, FinReceivableBatchDetail]
})
export class AccountsReceivableModule {
}