import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { BatchFinJob } from '.';
import { ModuleFinJobBreakdown } from '../fin-job-breakdown/module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NzIconModule,
    NzCollapseModule,
    ModuleFinJobBreakdown,
  ],
  declarations: [BatchFinJob],
  exports: [BatchFinJob]
})
export class ModuleBatchFinJob {}