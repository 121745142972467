import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Const} from '@const/Const';
import {BaseFormItem} from '@app/admin/base/form-item';
import {ActivatedRoute} from '@angular/router';
import {DateUtil} from '@services/date-utils';
import { FinApCountTab, FinApTabFilter } from '../../interface';
import { ResponseAdminFinAccountForFilter } from '@wearewarp/types/rest-api/admin/fin';
import { ConstFin } from '@wearewarp/js-const-finance';
import { Utils } from '@services/utils';

// interface 

@Component({
  selector: '[fin-payable-batch-list-filter]',
  templateUrl: './index.html',
  styleUrls: [ './index.scss' ]
})
export class FinPayableBatchListFilter extends BaseFormItem {
  @Input() set countSum(value: FinApCountTab) {
    for (let item of this.filterTabs) {
      if (typeof value[item.key] == 'number') {
        item.total = value[item.key];
      }
    }
  }
  @Output() onReload: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFilterTabChanged: EventEmitter<number> = new EventEmitter<number>();
  filterTabs = [
    {
      key: FinApTabFilter.needPodUpload,
      title: 'PODs upload needed',
      total: 0,
    },
    {
      key: FinApTabFilter.needPodConfirm,
      title: 'PODs confirm needed',
      total: 0,
    },
    {
      key: FinApTabFilter.readyToProcess,
      title: 'Ready to process',
      total: 0,
    },
    {
      key: FinApTabFilter.alreadyProcessed,
      title: 'Already processed',
      total: 0,
    },
    {
      key: FinApTabFilter.notFinished,
      title: 'Route not finished',
      total: 0,
    },
  ];
  private filterTabsIndex: FinApCountTab = {
    needPodUpload: 0,
    needPodConfirm: 1,
    readyToProcess: 2,
    alreadyProcessed: 3,
    notFinished: 4,
  };

  private condition: {accountIds?: string[], fromDate?: string, toDate?: string} = {};
  public searchKeyword: string = null;
  private sort: any = undefined;
  private page: number = 1;
  protected formGroupDeclaration: FormGroupDeclaration = FinPayableBatchListFilter.declaration;

  constructor(protected activatedRoute: ActivatedRoute) {
    super();
    this.activatedRoute.queryParams.subscribe(p => {
      if (p.filter) {
        try {
          this.condition = JSON.parse(p.filter);
        } catch {
          this.condition = {};
        }
      }
      this.sort = p.sort || undefined;
      this.searchKeyword = p.search;
      this.page = p.page || 1;
      let tab = p.tab || this.filterTabs[0].key;
      this._selectedTabIndex = this.filterTabsIndex[tab] || 0;
      this.onFilterTabChanged.emit(this._selectedTabIndex);
    });
    this.onRefresh();
  }

  public static get declaration(): FormGroupDeclaration {
    return {
      searchKeyword: {label: 'Keyword', placeHolder: '', notAcceptEmpty: true},
      accountIds: {label: 'Payee', notAcceptEmpty: true, placeHolder: 'Payee'},
      fromDate: {
        label: '',
        type: 'date',
        notAcceptEmpty: true,
        placeHolder: 'From date',
        formatValue: v => v,
        getValue: v => v
      },
      toDate: {
        label: '',
        type: 'date',
        notAcceptEmpty: true,
        placeHolder: 'To date',
        formatValue: v => v,
        getValue: v => v
      },
    };
  }

  private _selectedTabIndex = 0;

  get selectedTabIndex() {
    return this._selectedTabIndex;
  }

  set selectedTabIndex(value) {
    if (this._selectedTabIndex == value) {
      return;
    }
    this.sort = undefined;
    this._selectedTabIndex = value;
    this.onFilterTabChanged.emit(value);
    this.page = 1;
    // chuyển tab cần giữ nguyên điều kiện filter/search
    // this.condition = {};
    // this.formInput.reset();
    this.onRefresh();
  }

  ngOnInit(): void {
    this.model = Object.assign({}, this.condition, {search: this.searchKeyword});
    super.ngOnInit();
    this.fetchFinAccountList();
  }

  onChangeAccountId(value) {
    const key = 'accountIds';
    this.page = 1
    if (Utils.isArrayNotEmpty(value)) {
      this.condition[key] = value;
    } else {
      delete this.condition[key];
    }
    this.loadData()
  }

  public isFetchingFinAccounts = false;
  public listFinAccounts: ResponseAdminFinAccountForFilter[] = [];
  private fetchFinAccountList() {
    let url = Const.APIURI_FINANCES_BATCH(`fin_accounts?finType=${ConstFin.FinType.payable}`);
    this.isFetchingFinAccounts = true;
    this.api.GET(url).subscribe(
      resp => {
        this.listFinAccounts = resp.data.list_data;
        this.isFetchingFinAccounts = false;
      }, err => {
        this.showErr(err);
        this.isFetchingFinAccounts = false;
      }
    );
  }

  // isSelectPodNeededTab() {
  //   return this._selectedTabIndex == this.filterTabsIndex.need_pod_upload;
  // }

  // isSelectCompletedTab() {
  //   return this._selectedTabIndex == this.filterTabsIndex.processed;
  // }

  doSearch(s) {
    this.searchKeyword = s;
    this.page = 1;
    this.loadData();
  }

  onRefresh() {
    this.onReload.emit();
    if (this.sort) {
      this.sort = null;
    }
    this.loadData();
  }

  loadData() {
    let query = {page: this.page || 1, loaded: Date.now()};
    if (this.searchKeyword) {
      query['search'] = this.searchKeyword;
    }
    this.reformatConditionObject();
    query['tab'] = this.filterTabs[this._selectedTabIndex].key;
    query['filter'] = JSON.stringify({...this.condition, finType: ConstFin.FinType.payable});
    if (this.sort) {
      query['sort'] = this.sort;
    }
    this.routeWithQueryUrl(query);
  }

  // onChangeFilterDate(event, key) {
  //   this.page = 1;
  //   if (key == 'fromDate' && event) {
  //     this.condition[key] = DateUtil.getDateFrom(DateUtil.isoDate(event));
  //   } else if (key == 'toDate' && event) {
  //     this.condition[key] = DateUtil.getDateTo(DateUtil.isoDate(event));
  //   }else if (key) {
  //     this.condition[key] = event;
  //   } else {
  //     delete this.condition[key];
  //   }
  //   this.reformatConditionObject();
  //   this.loadData();
  // }

  private reformatConditionObject() {
    if (!Utils.isArrayNotEmpty(this.condition.accountIds)) {
      delete this.condition.accountIds;
    }
    if (this.condition.fromDate == null) {
      delete this.condition.fromDate;
    }
    if (this.condition.toDate == null) {
      delete this.condition.toDate;
    }
  }

}
