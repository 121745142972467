import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Const } from "@const/Const";
import { ResponseAdminFinBatchDetail, ResponseAdminFinBatchDetailFinJob } from "@wearewarp/types/rest-api/admin/fin";

@Component({
  selector: '[fin-batch]',
  templateUrl: './view.html',
  styleUrls: [ './style.scss' ]
})
export class FinBatch implements OnInit {
  @Input() data: ResponseAdminFinBatchDetail;
  relatedBatches: ResponseAdminFinBatchDetail[] = [];
  listFinJobs: ResponseAdminFinBatchDetailFinJob[] = [];

  private batchIdInUrl = '';
  get isAR(): boolean { return this.data?.finType == 'receivable' }
  get isAP(): boolean { return this.data?.finType == 'payable' }
  get shouldShowBatchHyperLink() { return this.batchIdInUrl != this.data.id }

  constructor(private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.listFinJobs = this.prepareFinJobs();
    this.batchIdInUrl = this.activatedRoute.snapshot.params['id'];
  }

  getRouterLink() {
    if (!this.shouldShowBatchHyperLink) return undefined;
    if (this.isAP) return [Const.routeAdminFinAP, this.data.id];
    else return [Const.routeAdminFinAR, this.data.id];
  }

  showBatchCode() {
    if (this.isAR) return `AR-${this.data.code}`;
    else if (this.isAP) return `AP-${this.data.code}`;
    else return this.data.code;
  }

  routerLinkBizEntity() {
    if (this.isAP) {
      return [Const.routeAdminDispatchList, this.data.relatedBiz.id];
    } else if (this.isAR) {
      return [Const.routeAdminOrderList, this.data.relatedBiz.id];
    }
  }

  routerLinkAccount() {
    if (this.isAP) {
      return [Const.routeAdminCarrierList, this.data.account.id];
    } else if (this.isAR) {
      return [Const.routeAdminClientList, this.data.account.id];
    }
  }

  private prepareFinJobs() {
    const arr = [];
    for (let item of this.data.finJobs) {
      if (Array.isArray(item.group)) {
        for (let groupMember of item.group) {
          (<any>groupMember).parent = item;
          arr.push(groupMember);
        }
      } else {
        arr.push(item);
      }
    }
    return arr;
  }

}