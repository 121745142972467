import { Component, Input, OnInit } from '@angular/core';
import { ResponseAdminFinBatchDetailFinJob } from '@wearewarp/types/rest-api/admin/fin';
import { ConstFin } from '@wearewarp/js-const-finance';
import { InputHelper } from '@services/input-helper';
import { FinType } from '@wearewarp/types';
import { Const } from '@const/Const';
import { Utils } from '@services/utils';

@Component({
  selector: '[batch-fin-job]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class BatchFinJob implements OnInit {
  @Input() parentFinJob: ResponseAdminFinBatchDetailFinJob | undefined;
  @Input() finJob: ResponseAdminFinBatchDetailFinJob;
  @Input() finType: FinType;

  isExpand = false;

  get isAR(): boolean { return this.finType == 'receivable' }
  get isAP(): boolean { return this.finType == 'payable' }

  ngOnInit(): void {
  }

  showFinJobCode(finJob) {
    return `FIN-${finJob?.code}`;
  }

  showFinJobStatus(finJob) {
    return finJob.intermediateStatus;
  }

  isAdditional(finJob) {
    return finJob.isAdditional;
  }

  toggleFinJobBreakdown() {
    this.isExpand = !this.isExpand
  }

  getAmtDesc(finJob) {
    if (finJob.isAdditional) {
      return this.isAR ? `Additional receivable amount` : `Additional payable amount`;
    } else {
      return this.isAR ? `Receivable amount` : `Payable amount`;
    }
  }

  isFinJobDone(finJob) {
    return finJob.intermediateStatus == ConstFin.FinJob_IntermediateStatus.alreadyProcessed;
  }

  showFinJobAmt(finJob) {
    return '$' + InputHelper._formatMoney(finJob.amt, 2);
  }

  showRelatedBizType() {
    return Utils.capitalizeFirstLetter(this.finJob.relatedBiz.type);
  }

  showRelatedBizCode() {
    return this.finJob.relatedBiz.code;
  }

  routerLinkRelatedBiz() {
    if (this.isAP) {
      return [Const.routeAdminDispatchList, this.finJob.relatedBiz.id];
    }
    if (this.parentFinJob) {
      return [Const.routeAdminOrderList, this.parentFinJob.relatedBiz.id];
    }
    return [Const.routeAdminOrderList, this.finJob.relatedBiz.id];
  }

}