import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { FinPayableBatchList } from './batch-list';
import { ModuleFinPayableBatchListFilter } from './batch-list/filter/module';
import { FinPayableBatchDetail } from './batch-detail';
import { ModuleFinBatch } from '../components/batch/module';
import { ModuleCarrierCostIssue } from '@app/admin/dispatch/components/carrier-and-driver/cost-issue/module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NzTableModule,
    NzTabsModule,
    NzIconModule,
    ModuleCarrierCostIssue,
    ModuleFinPayableBatchListFilter,
    ModuleFinBatch,
  ],
  declarations: [FinPayableBatchList, FinPayableBatchDetail],
  exports: [FinPayableBatchList, FinPayableBatchDetail]
})
export class AccountsPayableModule {
}